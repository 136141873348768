.human-body-report {
  width: calc(0.5 * 207px);
  position: relative;
  padding-top: calc(0.5 * 240px);
  height: calc(0.5 * 260px);
  display: block;
  margin: calc(0.5 * 40px) auto;
}

.human-body-report svg {
  position: absolute;
  left: 50%;
  fill: #57c9d5;
}

.human-body-report svg.head {
  margin-left: calc(0.5 * -28.5px);
  top: calc(0.5 * -6px);
}

.human-body-report svg.left-shoulder {
  margin-left: calc(0.5 * -53.5px);
  top: calc(0.5 * 69px);
}

.human-body-report svg.right-shoulder {
  margin-left: calc(0.5 * 13.5px);
  top: calc(0.5 * 69px);
}

.human-body-report svg.left-arm {
  margin-left: calc(0.5 * -78px);
  top: calc(0.5 * 112px);
}

.human-body-report svg.right-arm {
  margin-left: calc(0.5 * 38px);
  top: calc(0.5 * 112px);

}

.human-body-report svg.chest {
  margin-left: calc(0.5 * -43.5px);
  top: calc(0.5 * 88px);
}

.human-body-report svg.stomach {
  margin-left: calc(0.5 * -37.5px);
  top: calc(0.5 * 130px);
}

.human-body-report svg.left-leg {
  margin-left: calc(0.5 * -46.5px);
  top: calc(0.5 * 205px);

}

.human-body-report svg.right-leg {
  margin-left: calc(0.5 * 1.5px);
  top: calc(0.5 * 205px);

}

.human-body-report svg.left-hand {
  margin-left: calc(0.5 * -102.5px);
  top: calc(0.5 * 224px);
}

.human-body-report svg.right-hand {
  margin-left: calc(0.5 * 66.5px);
  top: calc(0.5 * 224px);

}

.human-body-report svg.left-foot {
  margin-left: calc(0.5 * -35.5px);
  top: calc(0.5 * 455px);
}

.human-body-report svg.right-foot {
  margin-left: calc(0.5 * 5.5px);
  top: calc(0.5 * 455px);
}

#area {
  display: block;
  width: 100%;
  clear: both;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  font-family: Courier New;
  color: #a5a5a5;
}
#area #data {
  color: black;
}
