[data-rbd-draggable-id="column-draggable-3"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #5cb860 !important;
}
[data-rbd-draggable-id="column-draggable-2"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #ffa21a !important;
}
[data-rbd-draggable-id="column-draggable-6"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #57c9d5 !important;
}
[data-rbd-draggable-id="column-draggable-4"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #57c9d5 !important;
}
[data-rbd-draggable-id="column-draggable-5"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #ef5350 !important;
}
[data-rbd-draggable-id="column-draggable-1"] {
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #57c9d5 !important;
}

[data-rbd-droppable-context-id="0"] {
  min-height: 100% !important;
}

/* element.style {
  overflow-y: hidden;
  display: flex;
  align-items: flex-start;
  /* min-height: 100% !important; 
}
<style>
.react-kanban-board {
  min-height: 100% !important;
  background-color: gray !important;
} */
[data-rbd-droppable-id="board-droppable"] {
  overflow-y: hidden;
  display: flex;
  min-height: 100% !important;
  display: flex;
}

.react-kanban-board {
  overflow-y: hidden;
  display: flex;
  height: unset !important;
  min-height: 100% !important;
}
.react-kanban-column {
  height: unset !important;
  align-items: stretch;
  text-align: center;
  min-width: 325px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 10px;
  opacity: 0.8;
  background-color: #57c9d5fd;
  margin: 10px;
  white-space: pre-line;
}

.react-kanban-card {
  width: 600px;
  text-align: start;
  font-weight: normal;
  border-radius: 5px;
  max-width: 300px;
  min-width: 300px;
  font-size: 13px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
  white-space: pre-line;
}

.react-kanban-card__title {
  font-size: small;
  text-decoration: underline;
  white-space: pre-line;
  font-weight: bold;
}

.react-kanban-card--dragging {
  background-color: rgb(16, 73, 75);
  color: white;
}
